var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('h1',{staticClass:"mt-2"},[_vm._v(" Посты "),_c('b-button',{attrs:{"to":{
        name: 'BlogPostCreate',
      }}},[_vm._v(" Добавить ")])],1),_c('b-table',{attrs:{"stacked":"lg","responsive":"","small":"","striped":"","borderless":"","items":_vm.items,"fields":_vm.fields,"busy":!_vm.posts.request.isSuccess},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[(_vm.posts.request.isLoading)?[_c('b-spinner',{staticClass:"align-middle"})]:_vm._e(),_c('strong',{staticClass:"ml-2"},[(_vm.posts.request.isLoading)?[_vm._v(" Загрузка постов... ")]:(_vm.posts.request.isError)?[_vm._v(" Ошибка загрузки постов. Повтор через "+_vm._s(_vm.postRequestDelay)+" сек. ")]:(_vm.posts.request.isFatalError)?[_vm._v(" Ошибка. Попробуйте позже. ")]:_vm._e()],2)],2)]},proxy:true},{key:"cell(show_details)",fn:function(row){return [_c('b-button',{attrs:{"size":"sm","to":{
          name: 'BlogPostEdit',
          params: {
            id: row.item.id,
          },
        }}},[_vm._v(" Изменить ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }