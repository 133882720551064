<template>
  <b-container>
    <h1 class="mt-2">
      Посты
      <b-button
        :to="{
          name: 'BlogPostCreate',
        }"
      >
        Добавить
      </b-button>
    </h1>

    <b-table
      stacked="lg"
      responsive small striped borderless
      :items="items"
      :fields="fields"
      :busy="!posts.request.isSuccess"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <template v-if="posts.request.isLoading">
            <b-spinner class="align-middle"></b-spinner>
          </template>
          <strong class="ml-2">
            <template v-if="posts.request.isLoading">
              Загрузка постов...
            </template>
            <template v-else-if="posts.request.isError">
              Ошибка загрузки постов. Повтор через {{ postRequestDelay }} сек.
            </template>
            <template v-else-if="posts.request.isFatalError">
              Ошибка. Попробуйте позже.
            </template>
          </strong>
        </div>
      </template>
      <template v-slot:cell(show_details)="row">
        <b-button
          size="sm"
          :to="{
            name: 'BlogPostEdit',
            params: {
              id: row.item.id,
            },
          }"
        >
          Изменить
        </b-button>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import map from 'lodash/map';
import { mapState } from 'vuex';

export default {
  name: 'BlogPostIndexPage',
  data() {
    return {
      posts: {
        request: {},
        value: undefined,
      },
      fields: [
        {
          key: 'slug',
          label: 'Slug',
        },
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'summary',
          label: 'Описание',
        },
        {
          key: 'show_details',
          label: 'Действие',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      timestamp: ({ timestamp }) => timestamp,
      currentLanguage: ({ language: { current } }) => current,
    }),
    postRequestDelay() {
      if (!this.posts.request.isError) {
        return 0;
      }
      const ms = this.posts.request.delay - (this.timestamp - this.posts.request.lastRequestTime);
      return Math.floor(ms / 1000) + 1;
    },
    items() {
      if (!this.posts.request.isSuccess) {
        return [];
      }

      if (!this.currentLanguage) {
        return this.posts.value;
      }

      return map(this.posts.value, (post) => ({
        ...post,
        name: post.name ? post.name[this.currentLanguage] : '',
        summary: post.summary ? post.summary[this.currentLanguage] : '',
      }));
    },
  },
  async beforeMount() {
    try {
      const { data: { posts } } = await this.axios.get('blog/posts', {
        'axios-retry': {
          retries: 5,
          beforeTry: () => {
            this.posts.request = {
              isLoading: true,
              isError: false,
            };
          },
          afterTry: ({ delay, lastRequestTime }) => {
            this.posts.request = {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime,
            };
          },
        },
      });
      this.posts.request = {
        isSuccess: true,
      };
      this.posts.value = posts;
    } catch (e) {
      this.posts.request = {
        isFatalError: true,
      };
    }
  },
};
</script>
